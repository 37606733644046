import React from 'react';
import * as PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from 'gatsby-image';
import { FormattedMessage } from "react-intl";

import Layout from '../components/layout';
import ContactForm from "../components/ContactForm";


const propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const AboutPage = ({data, location}) => {

  // const content = data.content.edges;
  const c = data.content.edges[0].node;

  return (
    <Layout data={data} location={location}>
      <article className="AboutPage Page">
        <section className="container grid-lg">
          <h1>{c.pageTitle}</h1>
          {c.pageContent && <div>{documentToReactComponents(c.pageContent.json)}</div>}
        </section>
        {/* <ContactForm /> */}
      </article>
    </Layout>
  );
};

AboutPage.propTypes = propTypes;

export default AboutPage;

export const aboutPageFragment = graphql`
    fragment AboutPageFragment on ContentfulAboutPageConnection {
      edges {
        node {
          node_locale
          pageTitle
          pageContent {
            json
          }
        }
      }
    }
  `;