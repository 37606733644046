import AboutPage from "../containers/AboutPage";

export default AboutPage;

export const pageQuery = graphql`
    query AboutPageEsQuery {
      site {
        siteMetadata {
          languages {
            langs
          }
        }
      }
      content: allContentfulAboutPage(
        filter: { node_locale: { eq: "es" } }
      ) {
        ...AboutPageFragment
      }
    }
  `;
